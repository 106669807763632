/**
 * Solid icons classes from https://fontawesome.com/
 */
export enum IconSolid
{
    trash = 'fa-solid fa-trash',
    undo = 'fa-solid fa-undo',
    undoAlt = 'fa-solid fa-undo-alt',
    user = 'fa-solid fa-user',
    userGear = 'fa-solid fa-user-gear',
    userSecret = 'fa-solid fa-user-secret',
    userCircle = 'fa-solid fa-user-circle',
    gear = 'fa-solid fa-gear',
    users = 'fa-solid fa-users',
    plus = 'fa-solid fa-plus',
    minus = 'fa-solid fa-minus',
    angleUp = 'fa-solid fa-angle-up',
    angleDown = 'fa-solid fa-angle-down',
    angleLeft = 'fa-solid fa-angle-left',
    angleRight = 'fa-solid fa-angle-right',
    running = 'fa-solid fa-running',
    extenalLinkAlt = 'fa-solid fa-external-link-alt',
    clock = 'fa-solid fa-clock',
    checkSquare = 'fa-solid fa-check-square',
    checkCircle = 'fa-solid fa-check-circle',
    calendar = 'fa-solid fa-calendar',
    calendarAlt = 'fa-solid fa-calendar-alt',
    star = 'fa-solid fa-star',
    lock = 'fa-solid fa-lock',
    lock_open = 'fa-solid fa-lock-open',
    key = 'fa-solid fa-key',
    fileLines = 'fa-solid fa-file-lines',
    fileExport = 'fa-solid fa-file-export',
    times = 'fa-solid fa-times',
    timesCircle = 'fa-solid fa-times-circle',
    edit = 'fa-solid fa-edit',
    puzzlePiece = 'fa-solid fa-puzzle-piece',
    columns = 'fa-solid fa-columns',
    fileExcel = 'fa-solid fa-file-excel',
    ellipsisV = 'fa-solid fa-ellipsis-v',
    arrowLeft = 'fa-solid fa-arrow-left',
    arrowRight = 'fas fa-arrow-right',
    ticketAlt = 'fa-solid fa-ticket-alt',
    bars = 'fa-solid fa-bars',
    home = 'fa-solid fa-home',
    sitemap = 'fa-solid fa-sitemap',
    mailBulk = 'fa-solid fa-mail-bulk',
    language = 'fa-solid fa-language',
    signOutAlt = 'fa-solid fa-sign-out-alt',
    exclamation = 'fa-solid fa-exclamation',
    exclamationTriangle = 'fa-solid fa-exclamation-triangle',
    th = 'fa-solid fa-th',
    filter = 'fa-solid fa-filter',
    sortAmountDown = 'fa-solid fa-sort-amount-down',
    indent = 'fa-solid fa-indent',
    chalkboard = 'fa-solid fa-chalkboard',
    bolt = 'fa-solid fa-bolt',
    bug = 'fa-solid fa-bug',
    search = 'fa-solid fa-search',
    qrcode = 'fa-solid fa-qrcode',
    play = 'fa-solid fa-play',
    pause = 'fa-solid fa-pause',
    stop = 'fa-solid fa-stop',
    circle = 'fa-solid fa-circle',
    chartArea = 'fa-solid fa-chart-area',
    meh = 'fa-solid fa-meh',
    mehRollingEyes = 'fa-solid fa-meh-rolling-eyes',
    mehBlank = 'fa-solid fa-meh-blank',
    box = 'fa-solid fa-box',
    tag = 'fa-solid fa-tag',
    check = 'fa-solid fa-check',
    skull = 'fa-solid fa-skull',
    exchange = 'fa-solid fa-exchange-alt',
    print = 'fa-solid fa-print',
    layerGroup = 'fa-solid fa-layer-group',
    list = 'fa-solid fa-list',
    cube = 'fa-solid fa-cube',
    wrench = 'fa-solid fa-wrench',
    industry = 'fa-solid fa-industry',
    file = 'fa-solid fa-file',
    truck = 'fa-solid fa-truck',
    flagCheckered = 'fa-solid fa-flag-checkered',
    thList = 'fa-solid fa-th-list',
    plane = 'fa-solid fa-plane',
    vectorSquare ='fa-solid fa-vector-square',
    chartPie = 'fa-solid fa-chart-pie',
    certificate = 'fa-solid fa-certificate',
    image = 'fa-solid fa-image',
    barcode = 'fa-solid fa-barcode',
    searchPlus = 'fa-solid fa-search-plus',
    infoCircle = 'fa-solid fa-info-circle',
    eye = 'fa-solid fa-eye',
    glasses = 'fa-solid fa-glasses',
    book = 'fa-solid fa-book',
    gaugeHigh = 'fa-solid fa-gauge-high',
    newspaper = 'fa-solid fa-newspaper',
    usersBetweenLines = 'fa-solid fa-users-between-lines',
    peopleGroup = 'fa-solid fa-people-group',
    userTie = 'fa-solid fa-user-tie',
    briefcase = 'fa-solid fa-briefcase',
    upload = 'fa-solid fa-upload',
    download = 'fa-solid fa-download'
}

/**
 * Regular icons classes from https://fontawesome.com/
 */
export enum IconRegular
{
    trash = 'fa-regular fa-trash',
    undo = 'fa-regular fa-undo',
    undoAlt = 'fa-regular fa-undo-alt',
    user = 'fa-regular fa-user',
    userGear = 'fa-regular fa-user-gear',
    userSecret = 'fa-regular fa-user-secret',
    userCircle = 'fa-regular fa-user-circle',
    gear = 'fa-regular fa-gear',
    users = 'fa-regular fa-users',
    plus = 'fa-regular fa-plus',
    minus = 'fa-regular fa-minus',
    angleUp = 'fa-regular fa-angle-up',
    angleDown = 'fa-regular fa-angle-down',
    angleLeft = 'fa-regular fa-angle-left',
    angleRight = 'fa-regular fa-angle-right',
    running = 'fa-regular fa-running',
    extenalLinkAlt = 'fa-regular fa-external-link-alt',
    clock = 'fa-regular fa-clock',
    checkSquare = 'fa-regular fa-check-square',
    checkCircle = 'fa-regular fa-check-circle',
    calendar = 'fa-regular fa-calendar',
    calendarAlt = 'fa-regular fa-calendar-alt',
    star = 'fa-regular fa-star',
    lock = 'fa-regular fa-lock',
    lock_open = 'fa-regular fa-lock-open',
    key = 'fa-regular fa-key',
    fileLines = 'fa-regular fa-file-lines',
    fileExport = 'fa-regular fa-file-export',
    times = 'fa-regular fa-times',
    timesCircle = 'fa-regular fa-times-circle',
    edit = 'fa-regular fa-edit',
    puzzlePiece = 'fa-regular fa-puzzle-piece',
    columns = 'fa-regular fa-columns',
    fileExcel = 'fa-regular fa-file-excel',
    ellipsisV = 'fa-regular fa-ellipsis-v',
    arrowLeft = 'fa-regular fa-arrow-left',
    arrowRight = 'fa-regular fa-arrow-right',
    ticketAlt = 'fa-regular fa-ticket-alt',
    bars = 'fa-regular fa-bars',
    home = 'fa-regular fa-home',
    sitemap = 'fa-regular fa-sitemap',
    mailBulk = 'fa-regular fa-mail-bulk',
    language = 'fa-regular fa-language',
    signOutAlt = 'fa-regular fa-sign-out-alt',
    exclamation = 'fa-regular fa-exclamation',
    exclamationTriangle = 'fa-regular fa-exclamation-triangle',
    th = 'fa-regular fa-th',
    filter = 'fa-regular fa-filter',
    sortAmountDown = 'fa-regular fa-sort-amount-down',
    indent = 'fa-regular fa-indent',
    chalkboard = 'fa-regular fa-chalkboard',
    bolt = 'fa-regular fa-bolt',
    bug = 'fa-regular fa-bug',
    search = 'fa-regular fa-search',
    qrcode = 'fa-regular fa-qrcode',
    play = 'fa-regular fa-play',
    pause = 'lr la-pause',
    stop = 'fa-regular fa-stop',
    circle = 'fa-regular fa-circle',
    chartArea = 'fa-regular fa-chart-area',
    meh = 'fa-regular fa-meh',
    mehRollingEyes = 'fa-regular fa-meh-rolling-eyes',
    mehBlank = 'fa-regular fa-meh-blank',
    box = 'fa-regular fa-box',
    tag = 'fa-regular fa-tag',
    check = 'fa-regular fa-check',
    skull = 'fa-regular fa-skull',
    exchange = 'fa-regular fa-exchange-alt',
    print = 'fa-regular fa-print',
    layerGroup = 'fa-regular fa-layer-group',
    list = 'fa-regular fa-list',
    cube = 'fa-regular fa-cube',
    wrench = 'fa-regular fa-wrench',
    industry = 'fa-regular fa-industry',
    file = 'fa-regular fa-file',
    truck = 'fa-regular fa-truck',
    flagCheckered = 'fa-regular fa-flag-checkered',
    thList = 'fa-regular fa-th-list',
    plane = 'fa-regular fa-plane',
    vectorSquare ='fa-regular fa-vector-square',
    chartPie = 'fa-regular fa-chart-pie',
    certificate = 'fa-regular fa-certificate',
    image = 'fa-regular fa-image',
    barcode = 'fa-regular fa-barcode',
    searchPlus = 'fa-regular fa-search-plus',
    infoCircle = 'fa-regular fa-info-circle',
    eye = 'fa-regular fa-eye',
    glasses = 'fa-regular fa-glasses',
    book = 'fa-regular fa-book',
    gaugeHigh = 'fa-regular fa-gauge-high',
    newspaper = 'fa-regular fa-newspaper',
    usersBetweenLines = 'fa-regular fa-users-between-lines',
    peopleGroup = 'fa-regular fa-people-group',
    userTie= 'fa-regular fa-user-tie',
    briefcase = 'fa-regular fa-briefcase',
    upload = 'fa-regular fa-upload',
    download = 'fa-regular fa-download'
}

export type Icon = IconSolid | IconRegular