import { useCallback } from 'react';

/**
 * Función que comprueba si la cadena pasada por parámetros
 * coincide con el patrón de un DNI de conductor.
 * @returns `function` que devuelve `true` si la cadena es la del DNI del conductor, `false` en caso contrario.
 */
export const useComprobarDNIConductor = () =>
    useCallback((searchResult: string) =>
    {
        const regexDNIConductor = /^\d{8}[A-Za-z]$/;
        const regexDNIConductorIncompleto = /^[A-Za-z]\d{7}[A-Za-z]$/;

        return regexDNIConductor.test(searchResult) || regexDNIConductorIncompleto.test(searchResult);
    }, []);

/**
 * Función que comprueba si la cadena pasada por parámetros
 * coincide con el patrón de una tarjeta de conductor.
 * @returns `function` que devuelve `true` si la cadena es la de una tarjeta de un conductor, `false` en caso contrario.
 */
export const useComprobarTarjetaConductor = () =>
    useCallback((searchResult: string) =>
    {
        const regexTarjetaCondcutor = /[A-Za-z]\d{8}[A-Za-z]\d{6}$/gm;

        const regexTarjetaCondcutorSegundaOpcion = /[A-Za-z][A-Za-z]\d{7}[A-Za-z]\d{6}$/gm;

        const regexTarjetaCondcutorIncompleta = /[A-Za-z]\d{8}[A-Za-z]$/gm;

        const regexTarjetaCondcutorSegundaOpcionIncompleta = /[A-Za-z][A-Za-z]\d{7}[A-Za-z]$/gm;

        return regexTarjetaCondcutor.test(searchResult) || regexTarjetaCondcutorSegundaOpcion.test(searchResult)
            || regexTarjetaCondcutorIncompleta.test(searchResult) || regexTarjetaCondcutorSegundaOpcionIncompleta.test(searchResult);
    }, []);