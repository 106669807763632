import { IconSolid } from 'Theme/Icons';
import { FunctionComponent, KeyboardEvent, useRef, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from 'Components/Core/Button/ButtonComponent';
import { useGet } from 'Components/Core/Hooks/GetHook';
import { useNavigate, useNavigationType } from 'Components/Core/Routes/Hooks/RouterHook';
import { useBackendFormService } from 'Components/Core/Services/Hooks/BackendFormHook';
import { useComprobarDNIConductor, useComprobarTarjetaConductor } from 'Components/FMT/C/C00/C00Hook';
import { useComprobarMatricula } from 'Components/FMT/V/V00/V00Hook';

import { IFinderProps } from './IFinderProps';

import './FinderTheme.scss';

/**
 * Finder component with search box.
 * @param {IFinderProps} props
 * @returns Finder
 */
export const Finder: FunctionComponent<IFinderProps> = ({ className = '' }: IFinderProps) =>
{
    // #region Hooks, variables y Constantes
    const { t } = useTranslation('/Core/Layout/Layout');
    const [searchValue, setSearchValue] = useState<string>('');
    const backendFormService = useBackendFormService('I000005');
    const navigate = useNavigate();
    const inputRef = useRef<HTMLInputElement>(null);
    // #endregion

    // #region Eventos
    /**
     * Este método se ejecuta cuando se pulsa enter
     * y se ejecuta la lógica de búsqueda
     * @param event
     */
    const onKeyUpSearch = (event: KeyboardEvent<HTMLInputElement>) =>
    {
        const searchValue = event.currentTarget.value.trim();

        // Si se pulsa enter y el valor de búsqueda no está vacío, se ejecuta la búsqueda
        if (event.key === 'Enter' && searchValue)
        {
            lanzarBusqueda(searchValue);
        }
    };

    const onClickSearch = (event: MouseEvent<HTMLButtonElement>) =>
    {
        const searchValue = inputRef.current && inputRef.current.value ?
            inputRef.current.value.trim()
            : '';

        if (searchValue)
        {
            lanzarBusqueda(searchValue, event.ctrlKey);
        }
    };
    // #endregion

    // #region Lógica de búsqueda.
    const lanzarBusqueda = async (searchValue: string, ctrlKey: boolean = false) =>
    {
        // Quitar espacios
        const sinEspaciosSearchValue = searchValue.replace(/\s+/g, '').trim();

        /**
         * 1) Matrícula
         *
         * Si la búsqueda es la matrícula de un vehículo,
         * se redirige a la ficha correspondiente a ese vehículo.
         */
        const esMatricula = await _comprobarMatricula(sinEspaciosSearchValue);

        if (esMatricula)
        {
            const codigoVehiculo = await backendFormService.get<string>('ObtenerCodigoVehiculo', { matricula: sinEspaciosSearchValue });

            if (codigoVehiculo != null)
            {
                navegarA(`/V000001/${codigoVehiculo}`, ctrlKey);

                return;
            }
        }

        /**
         * 2) DNI conductor
         *
         * Si la búsqueda es el DNI de un conductor,
         * se redirige a la ficha correspondiente de ese conductor.
         */
        const esDNIConductor = await comprobarExisteDNIConductor(sinEspaciosSearchValue );

        if (esDNIConductor)
        {
            const codigoConductor = await backendFormService.get<string>('ObtenerCodigoPersonaDNIConductor',  { dniConductor: sinEspaciosSearchValue });

            if (codigoConductor != null)
            {
                navegarA(`/C000001/${codigoConductor}`, ctrlKey);

                return;
            }
        }

        /**
         * 3) Tarjeta conductor
         *
         * Si la búsqueda es la tarjeta de un conductor,
         * se redirige a la ficha correspondiente de ese conductor.
         */

        const esTarjetaConductor = await comprobarExisteTarjetaConductor(sinEspaciosSearchValue);

        if (esTarjetaConductor)
        {
            const codigoConductor =  await backendFormService.get<string>('ObtenerCodigoPersonaTarjetaConductor', { tarjetaConductor: sinEspaciosSearchValue });

            if (codigoConductor != null)
            {
                navegarA(`/C000001/${codigoConductor}`, ctrlKey);

                return;
            }
        }

        // La búsqueda no coincide con ningún patrón (no es una matrícula,
        // DNI del conductor o tarjeta conductor) y redirige a I000005, donde
        // se mostrarán los resultados de la búsqueda.
        navegarA(`/I000005/${searchValue}`, ctrlKey);

        return;
    };

    const navegarA = (url: string, ctrlKey: boolean) =>
    {
        if (ctrlKey)
        {
            window.open(url);
        }
        else
        {
            navigate(url);
        }

        // Vaciamos la barra de búsqueda
        setSearchValue('');
    };

    // #region Comprobaciones
    const comprobarMatricula = useComprobarMatricula();

    const _comprobarMatricula = async (searchResult: string) =>
    {
        const coincideMatricula = comprobarMatricula(searchResult);

        if (coincideMatricula)
        {
            // Llamamos al back para comprobar si la matricula existe
            return coincideMatricula
                && await backendFormService.get<boolean>(
                    'ComprobarExisteMatricula',
                    { matricula: searchResult.toUpperCase() });
        }

        return coincideMatricula;
    };

    const comprobarDNIConductor = useComprobarDNIConductor();

    const comprobarExisteDNIConductor = async (searchResult: string) =>
    {
        const coincideConductor = comprobarDNIConductor(searchResult);

        if (coincideConductor)
        {
            // Llamamos al back para comprobar si el conductor existe
            return coincideConductor
                   && await backendFormService.get<boolean>(
                       'ComprobarExisteDNIConductor',
                       { dniConductor: searchResult.toUpperCase() });
        }

        return coincideConductor;
    };

    const comprobarTarjetaConductor = useComprobarTarjetaConductor();

    const comprobarExisteTarjetaConductor  = async (searchResult: string) =>
    {
        const coincideTarjetaConductor = comprobarTarjetaConductor(searchResult);

        if (coincideTarjetaConductor)
        {
            // Llamamos al back para comprobar si la tarjeta del conductor existe
            return coincideTarjetaConductor
                   && await backendFormService.get<boolean>(
                       'ComprobarExisteTarjetaConductor',
                       { tarjetaConductor: searchResult.toUpperCase() });
        }

        return coincideTarjetaConductor;
    };
    // #endregion
    // #endregion

    // #region Navigation history
    const { pathname } = useLocation();
    const navigationType =  useNavigationType();

    useGet((mount) =>
    {
        if (mount.current)
        {
            // Si hay navegación, vaciamos el valor del buscador
            if (navigationType === 'PUSH'
                || navigationType === 'POP')
            {
                setSearchValue('');
            }
        }
    }, [pathname, navigationType]);
    // #endregion

    // #region Render
    return (
        <div className={`${className} finder`}>
            <div>
                <input
                    className="searchbar form-control form-control-sm"
                    title={`${t('navTopbar.navSidebar.placeholderSearch')}...`}
                    type="search"
                    autoComplete="off"
                    ref={inputRef}
                    placeholder={`${t('navTopbar.navSidebar.placeholderSearch')}...`}
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.currentTarget.value)}
                    onKeyUp={onKeyUpSearch} />
                <i className={IconSolid.search} />
                <Button
                    fillMode={'flat'}
                    size={null}
                    iconClass={IconSolid.arrowRight}
                    title={t('navTopbar.navSidebar.search')}
                    onClick={onClickSearch} />
            </div>
        </div>
    );
    // #endregion
};

Finder.displayName = 'Finder';