import { Icon, IconRegular, IconSolid } from 'Theme/Icons';
import { useMemo, useState, VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AuthDTO } from 'Services/Authentication/IAuthenticationService';

import { Button } from 'Components/Core/Button/ButtonComponent';
import { useShowUnavailableFeatureDialog } from 'Components/Core/Dialog/Hooks/DialogHook';
import { useCurrentUser } from 'Components/Core/Services/Hooks/AuthenticationHook';

import { useActiveTransaction } from '../Transaction/Hooks/TransactionHook';

import { INavSidebarProps } from './INavSidebarProps';

import './NavSidebarTheme.scss';

/**
 * Navigation sidebar panel component.
 * @param {INavSidebarProps} props
 * @returns NavSidebar
 */
export const NavSidebar: VoidFunctionComponent<INavSidebarProps> = () =>
{
    // #region Hooks
    const { t } = useTranslation('/Core/Layout/Layout');
    const { activeTransaction } = useActiveTransaction();

    const user: AuthDTO = useCurrentUser();
    // #endregion

    // #region Panel de navegación
    const [expanded, setExpanded] = useState<boolean>(true);

    const toggleExpansion = () => setExpanded(previous => !previous);
    // #endregion

    // #region Render
    const functionKey = useMemo(() =>
        !['I000000', 'I000001', 'I000404'].some(value => value === activeTransaction.id) && activeTransaction.id.length >= 1 ?
            activeTransaction.id[0]
            : ''
    , [activeTransaction.id]);

    return (
        <nav id="navSidebar" className={`${functionKey}${expanded ? ' expanded' : ''}`}>
            <main>
                <LinkButton
                    url='/'
                    text={t('navSidebar.dashboard')}
                    icon={IconSolid.gaugeHigh} />
                <LinkButton
                    url='/C000000'
                    text={t('navSidebar.drivers')}
                    icon={IconSolid.peopleGroup} />
                <LinkButton
                    url='/V000000'
                    text={t('navSidebar.vehicles')}
                    icon={IconSolid.truck} />
                <LinkButton
                    url='/G000000'
                    text={t('navSidebar.companies')}
                    icon={IconSolid.userTie} />
                <LinkButton
                    url='/R000000'
                    text={t('navSidebar.reports')}
                    icon={IconRegular.newspaper} />
                <LinkButton
                    url='/F010000'
                    text={t('navSidebar.activities')}
                    icon={IconRegular.clock} />
                <LinkButton
                    url='/F000000'
                    text={t('navSidebar.files')}
                    icon={IconRegular.fileLines} />
                {user.administrator && <LinkButton
                    url='/S000000'
                    text={t('navSidebar.settings')}
                    icon={IconSolid.userGear} />
                }
                <div className='expandWrap'>
                    <Button
                        className='expandBtn'
                        onClick={toggleExpansion}
                        iconClass={expanded ? IconSolid.angleLeft : IconSolid.angleRight}
                        themeColor='primary'
                        fillMode='flat'
                        size='small'
                        title={expanded ? t('navTopbar.navSidebar.close') : t('navTopbar.navSidebar.open')}/>
                </div>
            </main>
        </nav>
    );
    // #endregion
};

NavSidebar.displayName = 'NavSidebar';

interface ILinkButtonProps {
    url: string;
    text: string;
    icon: Icon;
}

const LinkButton: VoidFunctionComponent<ILinkButtonProps> = ({ url, text, icon }) =>
{
    const showUnavailableFeatureDialog = useShowUnavailableFeatureDialog();

    return (
        <Link to={url} className='k-button k-button-sm k-button-flat k-button-flat-primary k-rounded-md'
            title={text}
            {...!url && { onClick: () => showUnavailableFeatureDialog() }}>
            <span className={`k-button-icon ${icon}`}></span>
            <span className='k-button-text'>{text}</span>
        </Link>
    );
};

LinkButton.displayName = 'LinkButton';