import { useCallback } from 'react';

/**
 * Función que comprueba si la cadena pasada por parámetros
 * coincide con el patrón de una matricula.
 * @returns `function` que devuelve `true` si la cadena es una matricula, `false` en caso contrario.
 */
export const useComprobarMatricula = () =>
    useCallback((searchResult: string) =>
    {
        const regexMatriculaEstandar = /^\d{4}[A-Za-z]{3}$/gm;

        const regexMatriculaSegundaOpcion = /^\[A-Za-z]{4}\d{4}$/gm;

        const redexMatriculaIncompleta = /^\d{4}$/;

        const redexMatriculaConUnaLetra = /^\d{4}[A-Za-z]{1}$/gm;

        return regexMatriculaEstandar.test(searchResult) ||
            regexMatriculaSegundaOpcion.test(searchResult) ||
            redexMatriculaIncompleta.test(searchResult) ||
            redexMatriculaConUnaLetra.test(searchResult);
    }, []);